*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.html,
body {
  background: #000 url("./Images/bg.jpg") no-repeat center center/cover;
  height: 100vh;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
}
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
}
.char_cards {
  display: grid;
  justify-content: center;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  padding: 0 2rem;
  margin: 2rem 25rem;
}
.char_card {
  padding: 1rem;
  border-radius: 1rem;
  background: linear-gradient(
    180deg,
    rgba(17, 45, 23, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  transition: all 0.3s linear;
}
.char_card:hover {
  box-shadow: 1px 1px 10px 7px rgb(17 45 23);
}
.char_img {
  width: 300px;
  height: 400px;
}
.char_name {
  margin-top: 1rem;
  text-align: center;
}

.pageButton {
  color: #112d17;
  background-color: #e3e7e4 !important;
}
.activeButton {
  color: #e3e7e4 !important;
  background-color: #112d17 !important;
}
.char_detail_main {
  display: grid;
  justify-content: center;
  place-items: center;
  min-height: 100vh;
  padding: 2rem;
}
.char_title {
  margin: 1.5rem auto;
  font-size: 2rem;
  color: #ffedb3;
}
.char_detail {
  color: #ffedb3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70vw;
  gap: 1rem;
}
.char_detail_img {
  text-align: center;
}
.detail_img {
  border-radius: 6px;
  max-width: 50%;
  object-fit: contain;
}
.char_info {
  width: 50%;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 1rem 1.5rem;
}
.char_info h3 {
  margin-top: 1rem;
}
.char_info h3 > span {
  font-weight: 300;
}
.underline {
  background-color: #ffedb3;
  border: 1px solid #ffedb3;
  border-radius: 3px;
  margin: 2rem auto;
  width: 40%;
}
.btn-back {
  background-color: #ffedb3;
  border: none;
  border-radius: 6px;
  color: #111;
  cursor: pointer;
  font-size: 1rem;
  margin: 0.5rem;
  padding: 0.75rem 1rem;
  transition: all 0.3s ease;
}
.btn-back:hover {
  background-color: #fadc7b;
}

/* Responsive Desing */

@media screen and (max-width: 1440px) {
  .char_cards {
    margin: 2rem 10rem;
  }
  .char_img {
    width: 200px;
    height: 300px;
  }
}
@media screen and (max-width: 1024px) {
  .char_cards {
    margin: 2rem 15rem;
  }
  .char_img {
    width: 150px;
    height: 250px;
  }
}
@media screen and (max-width: 900px) {
  .char_cards {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 600px) {
  .char_cards {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 450px) {
  .char_cards {
    grid-template-columns: repeat(1, 100%);
    padding: 0;
    margin: 2rem 0;
  }
  .char_img {
    width: 300px;
    height: 400px;
  }
}
